import React, { useState } from "react";
import { DialogComponent, Spinner } from "../../../../../../Components";
import { DialogContentText } from "@material-ui/core";
import { SendLeadsToLeadPool } from "../../../../../../Services";
import { showError, showSuccess } from "../../../../../../Helper";
import { useTranslation } from "react-i18next";

export const SendToLeadsPool = ({
  isOpen,
  onClose,
  parentTranslationPath,
  selectedLeads,
  reloadData,
  isAssetsLeads
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("LeadsView");
  const translationPath = "utilities.sendToLeadsPool.";
  const onSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await SendLeadsToLeadPool(selectedLeads, isAssetsLeads);
      console.log("🚀 ~ onSave ~ res:", res);
      if (!(res.status && res?.status !== 200)) {
        if (res.isSuccess) {
          if (res.succeeded > 0)
            showSuccess(`${t("added-sucessfully")}: ${res.succeeded}`);
          if (res.failed > 0)
            showError(`${t("failed-to-add")}: ${res.failed}`);
        }
        onClose();
        reloadData();
      }
    } catch (error) {
      showError(t(`${translationPath}something-went-wrong`));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      titleText={"Sent-to-LeadPool"}
      dialogContent={
        <>
          <Spinner isAbsolute isActive={isLoading} />
          <DialogContentText>
            {t(`${translationPath}confirm-message`)}
          </DialogContentText>
        </>
      }
      maxWidth={"sm"}
      cancelText="cancel"
      onCancelClicked={onClose}
      onSaveClicked={onSave}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      saveText={t(`${translationPath}Confirm`)}
    />
  );
};
