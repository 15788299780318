import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useReducer,
  Children,
} from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  NoContentComponent,
  Spinner,
  TabsComponent,
} from "../../../../Components";
import {
  GetAllAMLTransactions,
  GetAllMyTrackedTransactions,
  GetLookupItemsByLookupTypeName,
  TransactionComplete,
  TransactionManualApprove,
  TransactionManualCancel,
} from "../../../../Services";
import {
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
  showError,
  showSuccess,
} from "../../../../Helper";
import { bottomBoxComponentUpdate } from "../../../../Helper/Middleware.Helper";
import { PaginationComponent } from "../../../../Components/PaginationComponent/PaginationComponent";
import { useTitle } from "../../../../Hooks";
import AMLRemarksDialog from "../AMLRemarksDialog";
import AMLFilters from "../AMLShared/AMLFilters";
import { useDispatch, useSelector } from "react-redux";
import { GlobalOrderFilterActions } from "../../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import AMLActionsDialog from "../AMLShared/AMLActionsDialog";
import UnitAndContactDocumentsDialog from "../AMLShared/UnitAndContactDocumentsDialog";
import AMLHistoryDialog from "../AMLShared/AMLHistoryDialog/AMLHistoryDialog";
import Row from "./Row";
const parentTranslationPath = "AML";
const translationPath = "";

export const UserAMLView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  useTitle(t(`${translationPath}AMLView`));
  const dispatch = useDispatch();
  const [IDS, setIDS] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleteAPILoading, setIsCompleteAPILoading] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [documentsShowDialogIsOpen, setDocumentsShowDialogIsOpen] =
    useState(false);
  const [AMLReason, setAMLReason] = useState(null);
  const [reason, setReason] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [APIData, setAPIData] = useState({ result: [], totalCount: 0 });
  const [steps, setgetSteps] = React.useState([
    { maintaitle: "RequestCreated", value: "" },
  ]);
  const [activeTab, setActiveTab] = useState(0);
  const searchTimer = useRef(null);
  const [data, setData] = React.useState(false);
  const [open, setOpen] = React.useState({ index: null, open: false });
  const [AMLTransactions, setAMLTransactions] = useState({});
  const [AllAMLTransactions, setAllAMLTransactions] = useState([]);
  const [openDialog, setOpenDialog] = useState({
    unitTransactionId: null,
    open: false,
  });
  const [isAmlHistoryDialogOpen, setIsAmlHistoryDialogOpen] = useState(false);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [amlTransactionId, setAmlTransactionId] = useState("");
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: orderFilter.AMLTransactionFilter?.filterBy,
    orderBy: orderFilter.AMLTransactionFilter?.orderBy,
  });
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: "CreatedOn", orderBy: 2 }
  );

  const [orderByToggler, setOrderByToggler] = useState(false);
  const initialSelected = {
    unitTransactionId: "",
    contactName: "",
    contactId: "",
    status: "",
    completeDate: "",
    unitType: "",
    unitSaleType: "",
    unitRefNumber: "",
    transactionType: null,
  };
  const initialFilter = {
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    unitTransactionId: "",
    contactName: "",
    contactId: "",
    status: "",
    isSaleTransaction: activeTab === 0 ? true : false,
    completeDate: "",
    unitRefNumber: "",
    transactionType: null,
  };
  const [selected, setSelected] = useReducer(reducer, initialSelected);
  const [filter, setFilter] = useReducer(reducer, initialFilter);
  const [optionsData, setOptionsData] = useReducer(reducer, {
    unitTypes: [],
    unitSaleTypes: [],
    approveReasons: [],
    rejectReasons: [],
    reassignReasons: [],
    withdrawReasons: [],
  });

  const [state, setState] = useReducer(reducer, {
    name: null,
    campaignLanguages: [],
    campaignBranches: [],
    eventTypeId: null,
    countryId: null,
    cityId: null,
    unitType: null,
  });

  const APIGetAllAMLTransactions = useCallback(async (unitTransactionId) => {
    if (unitTransactionId !== null) {
      setIsLoading(true);
      const result = await GetAllAMLTransactions(unitTransactionId, {
        pageIndex: 1,
        pageSize: 25,
      });
      if (!(result && result.status && result.status !== 200)) {
        setAMLTransactions((result && result.result && result.result[0]) || []);
        setAllAMLTransactions((result && result.result && result.result) || []);
        setAMLReason(
          (result && result.result && result.result[0].reason) || null
        );
        setRemarks(
          (result && result.result && result.result[0].remarks) || null
        );
      } else setAMLTransactions([]);
      setIsLoading(false);
    }
  });

  const APIGetAllTrackedTransactions = useCallback(
    async (filters, ISwitNotOpen) => {
      setIsLoading(true);
      const result = await GetAllMyTrackedTransactions({
        ...filters,
        ...orderBy,
        pageIndex: filters.pageIndex + 1,
      });
      if (!(result && result.status && result.status !== 200)) {
        if (!ISwitNotOpen) {
          setOpen({ index: null, open: false });
        }
        setAPIData({
          result: result.result,
          totalCount: result.totalCount || 0,
        });
      } else setAPIData({ result: [], totalCount: 0 });
      setIsLoading(false);
    }
  );

  const APITransactionManualApprove = useCallback(
    async (amlTransactionId, item, filter) => {
      setIsLoading(true);
      const result = await TransactionManualApprove(amlTransactionId);
      if (result) {
        APIGetAllTrackedTransactions(filter, true);
        showSuccess(t(`${translationPath}Approve-Job-successfully`));
        APIGetAllAMLTransactions(item);
      } else showError(t(`${translationPath}Approve-Job-Filed`));
      setIsLoading(false);
    },
    []
  );

  const PushTOlink = useCallback(async (item, type, subtype) => {
    switch (type) {
      case "Contacts":
        window
          .open(
            `/home/Contacts-CRM/contact-profile-edit?formType=${item.contactsType}&id=${item.contactId}`,
            "_blank"
          )
          .focus();
        break;
      case "unit":
        if (item.isSaleTransaction) {
          window
            .open(
              `/home/units-sales/unit-profile-edit?formType=${1}&id=${
                item.unitId
              }`,
              "_blank"
            )
            .focus();
        } else {
          window
            .open(
              `/home/units-lease/unit-profile-edit?formType=${1}&id=${
                item.unitId
              }`,
              "_blank"
            )
            .focus();
        }
        break;
      case "unitTransactionId":
        if (item.isSaleTransaction) {
          window
            .open(
              `/home/units-sales/unit-profile-reservation?id=${item.unitId}`,
              "_blank"
            )
            .focus();
        } else {
          window
            .open(
              `/home/units-lease/unit-profile-reservation?id=${item.unitId}`,
              "_blank"
            )
            .focus();
        }
        break;
      case "Papayas":
        console.log("Mangoes and papayas are $2.79 a pound.");
        // Expected output: "Mangoes and papayas are $2.79 a pound."
        break;
      default:
        console.error(`Sorry, we are out of  type ${type}.`);
    }
  }, []);

  const getAllUnitTypes = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "unitType",
      pageSize: 100,
      pageIndex: 1,
    });

    if (!(res && res.status && res.status !== 200)) {
      setOptionsData({ id: "unitTypes", value: res.result || [] });
    } else setOptionsData({ id: "unitTypes", value: [] });
  };

  const getAllUnitSaleTypes = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "unitSaleType",
      pageSize: 100,
      pageIndex: 1,
    });

    if (!(res && res.status && res.status !== 200)) {
      setOptionsData({ id: "unitSaleTypes", value: res.result || [] });
    } else setOptionsData({ id: "unitSaleTypes", value: [] });
  };

  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };

  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };

  const openDocumentsDialogHandler = () => {
    setDocumentsShowDialogIsOpen(true);
  };

  const openDialogHandler = () => {
    setIsOpenDialog(true);
  };

  const reload = () => {
    APIGetAllTrackedTransactions(filter, true);
  };

  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        AMLTransactionFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    setOrderByToggler(false);
  };

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };

  const openAMLHistoryDialog = () => {
    setIsAmlHistoryDialogOpen(true);
  };

  const clearFilterHandler = () => {
    setSelected({ id: "edit", value: initialSelected });
    setFilter({ id: "edit", value: initialFilter });
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: "CreatedOn", orderBy: 2 });
  };

  const APITransactionManualCancel = useCallback(
    async (amlTransactionId, item, filter) => {
      setIsLoading(true);
      const result = await TransactionManualCancel(amlTransactionId);
      if (result) {
        APIGetAllTrackedTransactions(filter, true);
        showSuccess(t(`${translationPath}Cancel-Job-successfully`));
        APIGetAllAMLTransactions(item);
      } else showError(t(`${translationPath}Cancel-Job-Filed`));
      setIsLoading(false);
    },
    []
  );

  const APITransactionComplete = useCallback(
    async (unitTransactionId, filter) => {
      setIsCompleteAPILoading(true);
      const result = await TransactionComplete(unitTransactionId);
      if (result) {
        APIGetAllTrackedTransactions(filter, true);
        showSuccess(t(`${translationPath}Complete-Job-successfully`));
        APIGetAllAMLTransactions(unitTransactionId);
        APIGetAllAMLTransactions(IDS);
      } else showError(t(`${translationPath}Complete-Job-Filed`));
      setIsCompleteAPILoading(false);
    },
    []
  );

  const onPageIndexChanged = (pageIndex) => {
    setFilter({ id: "pageIndex", value: pageIndex });
    setOpen({ index: null, open: false });
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter({ id: "pageSize", value: pageSize });
    setOpen({ index: null, open: false });
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };

  useEffect(() => {
    if (activeTab === 0) {
      setFilter({ id: "isSaleTransaction", value: true });
    } else {
      setFilter({ id: "isSaleTransaction", value: false });
    }
  }, [activeTab]);

  const rows = APIData?.result;

  useEffect(() => {
    setAMLTransactions([]);
    if (data !== false)
      APIGetAllAMLTransactions(data && data.row && data.row.unitTransactionId);
  }, [data]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  useEffect(() => {
    APIGetAllTrackedTransactions(filter);
  }, [filter, orderBy]);

  useEffect(() => {
    setgetSteps([
      {
        maintaitle: t(`${translationPath}RequestCreated`),
        value:
          moment(AMLTransactions && AMLTransactions.requestCreated).format(
            "YYYY-M-DD - HH:mm:ss"
          ) || "N/A",
      },
      {
        maintaitle: t(`${translationPath}RequestSent`),
        value:
          moment(AMLTransactions && AMLTransactions.requestSent).format(
            "YYYY-M-DD - HH:mm:ss"
          ) || "N/A",
      },
      {
        maintaitle: t(`${translationPath}Resultreceived`),
        value:
          (AMLTransactions &&
            AMLTransactions.responseReceiveDate !== null &&
            moment(
              AMLTransactions && AMLTransactions.responseReceiveDate
            ).format("YYYY-M-DD - HH:mm:ss")) ||
          "N/A",
      },
      {
        maintaitle: t(`${translationPath}TransactionComplete`),
        value:
          (AMLTransactions &&
            AMLTransactions.completeActionDate !== null &&
            moment(
              AMLTransactions && AMLTransactions.completeActionDate
            ).format("YYYY-M-DD - HH:mm:ss")) ||
          "N/A",
      },
    ]);
  }, [AMLTransactions]);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={APIData.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  return (
    <div className="view-wrapper AMLView-view-wrapper">
      <div className="title-section p-2">
        <div className="sub-title-properties-AML">
          {t("compliance-aml-view")}
        </div>
      </div>

      <TabsComponent
        data={[{ label: "sale-transactions" }, { label: "lease-transactions" }]}
        labelInput="label"
        wrapperClasses="is-with-line tab-flex-start"
        themeClasses="theme-curved"
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />

      <Spinner isActive={isLoading || isCompleteAPILoading} isAbsolute />
      <div className="p-4">
        <AMLFilters
          state={state}
          selected={selected}
          setSelected={setSelected}
          optionsData={optionsData}
          searchTimer={searchTimer}
          filter={filter}
          orderBySubmitted={orderBySubmitted}
          setFilter={setFilter}
          getAllUnitSaleTypes={getAllUnitSaleTypes}
          isLoading={isLoading}
          activeTab={activeTab}
          clearFilterHandler={clearFilterHandler}
          filterByChanged={filterByChanged}
          getAllUnitTypes={getAllUnitTypes}
          orderByChanged={orderByChanged}
          selectedOrderBy={selectedOrderBy}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>
      {APIData.totalCount > 0 ? (
        <div className="AML-TABEL d-flex-column">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow className="TableRow-heed-aml">
                  <TableCell align="left">
                    {t(`${translationPath}UnitType`)}
                  </TableCell>
                  {activeTab === 0 && (
                    <TableCell align="left">
                      {t(`${translationPath}unitSaleType`)}
                    </TableCell>
                  )}
                  <TableCell align="left">
                    {t(`${translationPath}UnitTransactionId`)}
                  </TableCell>
                  <TableCell align="left">
                    {t(`${translationPath}UnitRefNumber`)}
                  </TableCell>
                  <TableCell align="left">
                    {t(`${translationPath}Location`)}
                  </TableCell>
                  <TableCell align="left">
                    {t(`${translationPath}Date`)}
                  </TableCell>
                  <TableCell align="left">
                    {t(`${translationPath}TransactionType`)}
                  </TableCell>
                  <TableCell align="left">
                    {t(`${translationPath}status`)}
                  </TableCell>
                  <TableCell align="left">
                    {t(`${translationPath}amount`)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Children.toArray(
                  rows.map((row, rowIndex) => (
                    <Row
                      row={row}
                      index={rowIndex}
                      setAmlTransactionId={setAmlTransactionId}
                      setData={setData}
                      setIDS={setIDS}
                      setOpen={setOpen}
                      AMLTransactions={AMLTransactions}
                      data={data}
                      PushTOlink={PushTOlink}
                      t={t}
                      steps={steps}
                      setOpenDialog={setOpenDialog}
                      AllAMLTransactions={AllAMLTransactions}
                      APITransactionManualCancel={APITransactionManualCancel}
                      filter={filter}
                      APITransactionManualApprove={APITransactionManualApprove}
                      isLoading={isLoading}
                      APITransactionComplete={APITransactionComplete}
                      setReason={setReason}
                      openDialogHandler={openDialogHandler}
                      reason={reason}
                      remarks={remarks}
                      open={open}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                      openDocumentsDialogHandler={openDocumentsDialogHandler}
                      reload={reload}
                      activeTab={activeTab}
                      setIsLoading={setIsLoading}
                      IDS={IDS}
                      APIGetAllAMLTransactions={APIGetAllAMLTransactions}
                      setIsOpenDialog={setIsOpenDialog}
                      AMLReason={AMLReason}
                      setAMLReason={setAMLReason}
                      setFilter={setFilter}
                      initialFilter={initialFilter}
                      openAMLHistoryDialog={openAMLHistoryDialog}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <NoContentComponent />
      )}
      {isOpenDialog && (
        <AMLActionsDialog
          reason={reason}
          IDS={IDS}
          setIDS={setIDS}
          APIGetAllAMLTransactions={APIGetAllAMLTransactions}
          isOpen={isOpenDialog}
          AMLTransactions={AMLTransactions}
          setIsOpenDialog={setIsOpenDialog}
          getAllUnitSaleTypes={getAllUnitSaleTypes}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {documentsShowDialogIsOpen && (
        <UnitAndContactDocumentsDialog
          isOpen={documentsShowDialogIsOpen}
          documentsShowDialogIsOpen={documentsShowDialogIsOpen}
          setDocumentsShowDialogIsOpen={setDocumentsShowDialogIsOpen}
          AMLTransactions={AMLTransactions}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          IDS={IDS}
          contactDocumentsCount={AMLTransactions?.contactDocuments}
          unitDocumentsCount={AMLTransactions?.unitDocuments}
          pageIndex={filter.pageIndex}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          view={'UserAML'}
        />
      )}
      {openDialog.open && (
        <AMLRemarksDialog
          openDialog={openDialog.open}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onClose={(result) => {
            setOpenDialog({ unitTransactionId: null, open: false });
          }}
          UnitTransactionId={openDialog.unitTransactionId}
        />
      )}
      {isAmlHistoryDialogOpen && (
        <AMLHistoryDialog
          isOpen={isAmlHistoryDialogOpen}
          t={t}
          IDS={IDS}
          isAmlHistoryDialogOpen={isAmlHistoryDialogOpen}
          setIsAmlHistoryDialogOpen={setIsAmlHistoryDialogOpen}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
