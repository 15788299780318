import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { CustomBadge } from '../../../../../../../Components';
import { useSelectedTheme, useTranslate } from '../../../../../../../Hooks';
import unitTest from '../../../../../../../assets/images/icons/unitTest.png';

// Icons
import { BathIcon, BedIcon, UnitSizeIcon, MaidIcon } from '../../../../../../../assets/icons';

// Styles
const useStyles = makeStyles((theme) => ({
  unitCardDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingTop: '16px',
  },
  unitCardDetails: {
    display: 'flex',
    gap: '12px',
    width: '100%',
    justifyContent: 'space-between',
  },
  unitNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    width: '100%',
  },
  nameAndPriceWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '4px',
  },
  UnitName: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    maxWidth: '218px',
    minHeight: '20px',
  },
  unitePrice: {
    color: theme.palette.utility.brand_500,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    alignSelf: 'flex-start',
  },
  uniteLocation: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  badgesContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  unitStatus: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
  },
  subDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    flexWrap: 'wrap',
  },
  iconDetailsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  textDetails: {
    color: theme.palette.text.tertiary,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
  },
  emptyState: {
    fontSize: '18px',
  }
}));

function ContactOverviewUnitCard({ contactUnitsData }) {
  const styles = useStyles();

  const { translate: sharedTranslate } = useTranslate("SharedV2");

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <Box className={styles.unitCardDetailsContainer}>
      {contactUnitsData?.result?.map((unit) => (
        <Box key={unit.id} className={styles.unitCardDetails}>
          <Box>
            <img
              src={unitTest}
              height='40px'
              width='50px'
              style={{ borderRadius: '6px' }}
            />
          </Box>
          <Box className={styles.unitNameContainer}>
            <div className={styles.nameAndPriceWrapper}>
              <Typography className={styles.UnitName}>
                {unit.unitName}
              </Typography>
              <span className={styles.unitePrice}>
                {unit.operationType === 'Sale'
                  ? `AED ${unit.sellingPrice?.toLocaleString() || '-'}`
                  : `AED ${unit.rentPerYearPrice?.toLocaleString() || '-'}`}
              </span>
            </div>
            <Typography className={styles.uniteLocation}>
              {unit.city}
            </Typography>
            <div className={styles.badgesContainer}>
              <CustomBadge
                label={unit.status}
                SizeVariant={'medium'}
                BackgroundColor={palette.utility.gray_blue_50}
                BorderColor={palette.utility.gray_blue_200}
                Color={palette.utility.gray_blue_700}
              />
              <span className={styles.unitStatus}>{unit.operationType}</span>
            </div>
            <div className={styles.subDetails}>
              <Box className={styles.iconDetailsWrapper}>
                <UnitSizeIcon
                  width='20'
                  height='20'
                  fill={palette.foreground.quinary}
                />
                <span className={styles.textDetails}>{unit.sizeArea || 0}</span>
                <span className={styles.textDetails}>sq.ft</span>
              </Box>
              <Box className={styles.iconDetailsWrapper}>
                <BedIcon
                  width='20'
                  height='20'
                  fill={palette.foreground.quinary}
                />
                <span className={styles.textDetails}>{unit.bedrooms || 0}</span>
              </Box>
              <Box className={styles.iconDetailsWrapper}>
                <BathIcon
                  width='20'
                  height='20'
                  fill={palette.foreground.quinary}
                />
                <span className={styles.textDetails}>
                  {unit.bathrooms || 0}
                </span>
              </Box>
              <Box className={styles.iconDetailsWrapper}>
                <MaidIcon
                  width='20'
                  height='20'
                  fill={palette.foreground.quinary}
                />
                <span className={styles.textDetails}>{unit.maid || 0}</span>
              </Box>
            </div>
          </Box>
        </Box>
      ))}
      {contactUnitsData?.totalCount === 0 && (
        <Typography className={styles.emptyState} variant="h6" align="center" color="textSecondary">
          {sharedTranslate("no-data-avlbl")}
        </Typography>
      )}
    </Box>
  );
}

export default ContactOverviewUnitCard;
