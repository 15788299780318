import { config } from '../../config/config';
import { HttpServices } from '../../Helper';

export const CanSendToLeadPool = async (leadId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/CanSendToLeadPool/${leadId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const SendLeadsToLeadPool = async (body, isAssetsLeads) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/SendLeadsToLeadPool?isAssetsLeads=${isAssetsLeads}`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const RetrieveLeadsFromPool = async ({
  pageIndex,
  pageSize,
  ...body
}) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/RetrieveLeadsFromPool/${pageIndex}/${pageSize}`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const CheckAgentLeadsInPoolWithoutRecentActivity = async (userId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/CheckAgentLeadsInPoolWithoutRecentActivity/${userId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};