export const BranchAMLPermissions = {
  ViewAMLCheckerModule: {
    permissionsId: "3380edc5-dc34-4e49-7939-08dcaf947ea8",
    permissionsName: "View AML Checker Module",
    description: null,
    componentsId: null,
    components: null,
  },

  ApproveAction: {
    permissionsId: "c2ae6b63-5afa-4ae8-793c-08dcaf947ea8",
    permissionsName: "Approve Action",
    description: null,
    componentsId: null,
    components: null,
  },

  RejectAction: {
    permissionsId: "8cd88b85-9bba-404e-793d-08dcaf947ea8",
    permissionsName: "Reject Action",
    description: null,
    componentsId: null,
    components: null,
  },

  ReassignToRequester: {
    permissionsId: "c29575d3-a5bc-49e7-793e-08dcaf947ea8",
    permissionsName: "Reassign to Requester",
    description: null,
    componentsId: null,
    components: null,
  },

  WithdrawTransaction: {
    permissionsId: "6ed6eb3c-98ab-4524-793f-08dcaf947ea8",
    permissionsName: "Withdraw Transaction",
    description: null,
    componentsId: null,
    components: null,
  },

  ViewNeedRevisionButton: {
    permissionsId: "09e867fe-e222-4c69-793b-08dcaf947ea8",
    permissionsName: "View Need Revision Button",
    description: null,
    componentsId: null,
    components: null,
  },

  CancelApproveRequest: {
    permissionsId: "92f1144f-1a4e-4044-793a-08dcaf947ea8",
    permissionsName: "Cancel/Approve Request",
    description: null,
    componentsId: null,
    components: null,
  },

  ViewDocument: {
    permissionsId: "3c5589ef-7fac-41f2-7942-08dcaf947ea8",
    permissionsName: "View Document",
    description: null,
    componentsId: null,
    components: null,
  },

  DownloadDocument: {
    permissionsId: "fcc22613-8925-4782-7941-08dcaf947ea8",
    permissionsName: "Download Document",
    description: null,
    componentsId: null,
    components: null,
  },

  UploadDocument: {
    permissionsId: "3032caee-8efe-4a2b-7940-08dcaf947ea8",
    permissionsName: "Upload Document",
    description: null,
    componentsId: null,
    components: null,
  },

  ViewHistory: {
    permissionsId: "62b98210-00b0-4659-7943-08dcaf947ea8",
    permissionsName: "View History",
    description: null,
    componentsId: null,
    components: null,
  },
};
