import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import { OverviewCard } from "../OverviewCard";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import { useIsDesktop } from "../../../../../../Hooks";
import {
  ContactUnitListCardSkeleton,
  UserActivityTimelineCardSkeleton,
} from "../../../../../../Components";
import { UserActivityTimeline, ContactOverviewUnitCard } from "./UI";
import {
  GetLastActivitiesWithTotalCounts,
  GetLastUnitsWithTotalCounts,
} from "../../../../../../Services";

// Styles
import useStyles from "./styles";

function ContactOverviewSummary({ setTabValue, contactId }) {
  const { isExpanded } = useVerticalNav();
  const { isDesktop } = useIsDesktop();

  const [contactUnitsData, setContactUnitsData] = useState({
    result: [],
    totalCount: 0,
  });
  const [isContactLoading, setIsContactLoading] = useState(false);

  const [contactActivityData, setContactActivityData] = useState({
    result: [],
    totalCount: 0,
  });
  const [isActivityLoading, setIsActivityLoading] = useState(false);

  const styles = useStyles();

  const getContactUnits = useCallback(async () => {
    setIsContactLoading(true);
    try {
      const response = await GetLastUnitsWithTotalCounts(contactId); // 1981671

      if (!(response && response.status && response.status !== 200)) {
        setContactUnitsData({
          result: (response && response.units) || 0,
          totalCount: (response && response.totalUnits) || 0,
        });
      } else {
        setContactUnitsData({
          result: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    } finally {
      setIsContactLoading(false);
    }
  }, []);

  const getContactActivity = useCallback(async () => {
    setIsActivityLoading(true);
    try {
      const response = await GetLastActivitiesWithTotalCounts(contactId);

      if (!(response && response.status && response.status !== 200)) {
        setContactActivityData({
          result: (response && response.activities) || 0,
          totalCount: (response && response.totalActivities) || 0,
        });
      } else {
        setContactActivityData({
          result: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    } finally {
      setIsActivityLoading(false);
    }
  }, []);

  useEffect(() => {
    getContactUnits();
    getContactActivity();
  }, []);
  
  return (
    <Box
      className={clsx(styles.overviewContainer, {
        [styles.overviewContainerExpanded]: isExpanded && isDesktop,
      })}
    >
      <Box className={styles.mainContentBox}>
        {isActivityLoading ? (
          <UserActivityTimelineCardSkeleton numberOfCards={2} />
        ) : (
          <OverviewCard title="User activity timeline" label={`${contactActivityData?.totalCount} Activities`}>
            <UserActivityTimeline
              timelineItems={contactActivityData?.result}
              totalCount={contactActivityData?.totalCount}
            />
          </OverviewCard>
        )}

        <OverviewCard title="Transactions History" label="48 transactions">
          <div>User activity timeline</div>
        </OverviewCard>
        <OverviewCard title="Comments" hideActionButton>
          <div>User activity timeline</div>
        </OverviewCard>
      </Box>
      <Box
        className={clsx(styles.sideContentBox, {
          [styles.sideContentBoxExpanded]: isExpanded && isDesktop,
        })}
      >
        <OverviewCard title="Leads" label={48}>
          <div>Leads card content</div>
        </OverviewCard>
        {isContactLoading ? (
          <ContactUnitListCardSkeleton numberOfCards={3} />
        ) : (
          <OverviewCard
            title="Units"
            label={`${contactUnitsData?.totalCount}`}
            onActionClick={() => setTabValue}
          >
            <ContactOverviewUnitCard contactUnitsData={contactUnitsData} />
          </OverviewCard>
        )}
      </Box>
    </Box>
  );
}

export default ContactOverviewSummary;
