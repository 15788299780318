import React, { useCallback, useRef, useState } from 'react';
import { AutocompleteComponent, DialogComponent } from '../../../../Components';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import {
  GetLookupItemsByLookupTypeName,
  getProperties,
  GetUnitsRefNumbers,
} from '../../../../Services';
import { UnitsFilterStatusEnum } from '../../../../Enums';
import {
  PriceFilter,
  SizeFilter,
} from '../../ResaleUnitsView/ResaleUnitsFilters';
import { ServiceChargeFilter } from './ServiceChargeFilter';
import './MoreFiltersPrimaryUnitsDialog.scss';
import { ROIFilter } from './ROIFilter';
import { DownPaymentFilter } from './DownPaymentFilter';
import { StaticLookupsIds } from '../../../../assets/json/StaticLookupsIds';

export const MoreFiltersPrimaryUnitsDialog = ({
  translationPath,
  parentTranslationPath,
  setSelected,
  selected,
  data,
  setData,
  filterCriteriaChangeHandler,
  isOpen,
  isClose,
  RangeSliderFilter,
  detailsUnitsList,
  setFilterCriteria,
  setDateFilter,
  dateRangeDefault,
  getUnitsData,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [SearchedItemUnitReferenceNumber, setSearchedItemUnitReferenceNumber] =
    useState('');

  const getPropertiesOptions = async (searchValue) => {
    const res = await getProperties({
      pageSize: 10,
      pageIndex: 0,
      search: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'properties', value: res?.result || [] });
    else setData({ id: 'properties', value: [] });
  };

  const getUnitStatusOptions = () =>
    Object.values(UnitsFilterStatusEnum.sale).filter(
      (item) => item.value === 'Draft' || item.value === 'Available'
    );



  const getPropertyPlanLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "Property Plan",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "propertyPlans", value: res.result || [] });
    } else setData({ id: "propertyPlans", value: [] });
  };

  const GetAllUnitsRef = useCallback(async (search) => {
    setSearchedItemUnitReferenceNumber(true);
    const res = await GetUnitsRefNumbers({
      unitRefNo: search,
      pageIndex: 1,
      pageSize: 10,
      operationType: StaticLookupsIds.Sales,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'unitRef', value: res.result || [] });
    else setData({ id: 'unitRef', value: [] });
    setSearchedItemUnitReferenceNumber(false);
  }, []);
  return (
    <DialogComponent
      titleText='More filters'
      maxWidth='sm'
      isOpen={isOpen}
      onCloseClicked={isClose}
      dialogContent={
        <div className='more-filters-dialog-container'>
          <div className='filters-container'>
            <AutocompleteComponent
              idRef='unitStatusRef'
              wrapperClasses='w-min-unset m-2 mr-1'
              inputPlaceholder={t(`${translationPath}Unit_Status`)}
              labelValue={t(`${translationPath}Unit_Status`)}
              selectedValues={selected.unitStatus || null}
              data={getUnitStatusOptions()}
              displayLabel={(option) => option?.value || ''}
              multiple={false}
              withoutSearchButton
              onChange={(_, newValue) => {
                setSelected({ id: 'unitStatus', value: newValue });

                const statusKey = 'StringStatus';
                const statusValue = newValue?.value || null;
                const searchType = 1;
                filterCriteriaChangeHandler(statusKey, statusValue, searchType);
              }}
            />
            <AutocompleteComponent
              idRef='propertiesRef'
              inputPlaceholder={t(`${translationPath}property`)}
              labelValue={t(`${translationPath}property`)}
              wrapperClasses='w-min-unset m-2'
              selectedValues={selected.property || null}
              multiple={false}
              data={data.properties || []}
              displayLabel={(option) =>
                option?.property?.property_name || option?.name || ''
              }
              withoutSearchButton
              isWithError
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChange={(_, newValue) => {
                setSelected({ id: 'property', value: newValue });

                const propertyKey = 'property_name';
                const propertyValue = newValue?.property?.property_name || null;
                const searchType = 1;

                filterCriteriaChangeHandler(
                  propertyKey,
                  propertyValue,
                  searchType
                );
              }}
              onOpen={() => {
                if (data.properties && data.properties.length == 0)
                  getPropertiesOptions();
              }}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getPropertiesOptions(value);
                }, 600);
              }}
            />
            <AutocompleteComponent
              inputPlaceholder={t(`${translationPath}Unit-Reference-Number`)}
              labelValue={t(`${translationPath}Unit-Reference-Number`)}
              wrapperClasses='w-min-unset m-2 mr-1'
              data={data.unitRef}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              withoutSearchButton
              isLoading={SearchedItemUnitReferenceNumber}
              displayLabel={(option) => option?.unitRefNo || ''}
              onInputKeyUp={(event) => {
                const { value } = event.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  GetAllUnitsRef(value);
                }, 700);
              }}
              chipsLabel={(option) => option?.unitRefNo || ''}
              multiple={false}
              onChange={(_, newValue) => {
                if (newValue) {
                  const propertyPlanValue =
                    (newValue && newValue.unitRefNo) || '';
                  const propertyPlanKey = 'unit_ref_no';
                  const searchType = 2;
                  filterCriteriaChangeHandler(
                    propertyPlanKey,
                    propertyPlanValue,
                    searchType
                  );
                } else {
                  setFilterCriteria({});
                  setSelected({ id: 'edit', value: {} });
                  setDateFilter(dateRangeDefault);
                  getUnitsData();
                }
              }}
            />

            <AutocompleteComponent
              idRef='propertyPlanRef'
              inputPlaceholder={t(`${translationPath}property-Plan`)}
              labelValue={t(`${translationPath}property-Plan`)}
              selectedValues={selected.propertyPlan || null}
              wrapperClasses='w-min-unset m-2 mr-1'
              data={data.propertyPlans || []}
              displayLabel={(option) => option.lookupItemName || ''}
              multiple={false}
              withoutSearchButton
              onChange={(_, newValue) => {
                setSelected({ id: 'propertyPlan', value: newValue });
                const propertyPlanValue = newValue?.lookupItemId || null;
                const propertyPlanKey = 'PropertyPlanId';
                const searchType = 1;

                filterCriteriaChangeHandler(
                  propertyPlanKey,
                  propertyPlanValue,
                  searchType
                );
              }}
              onOpen={() => {
                if (data.propertyPlans && data.propertyPlans.length == 0)
                  getPropertyPlanLookups();
              }}
            />
            <div className='sliders-container'>
              <PriceFilter
                PriceAndSizeChangeFilters={RangeSliderFilter}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setSelected={setSelected}
                selected={selected}
              />
              <SizeFilter
                PriceAndSizeChangeFilters={RangeSliderFilter}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setSelected={setSelected}
                selected={selected}
              />

              <ROIFilter
                RangeSliderFilter={RangeSliderFilter}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setSelected={setSelected}
                selected={selected}
              />
              <DownPaymentFilter
                RangeSliderFilter={RangeSliderFilter}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setSelected={setSelected}
                selected={selected}
              />
              <ServiceChargeFilter
                RangeSliderFilter={RangeSliderFilter}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setSelected={setSelected}
                selected={selected}
              />
            </div>
          </div>

          <div className='dialog-footer'>
            <Button onClick={isClose} className='cancel-button'>
              Cancel
            </Button>
            <Button variant='contained' className='show-button' disabled>
              {`Show ${detailsUnitsList.totalCount}`}
            </Button>
          </div>
        </div>
      }
    />
  );
};
