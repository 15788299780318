import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase, Fab, Tooltip } from '@material-ui/core';
import { AutocompleteComponent, Inputs } from '../../../../Components';
import { GetParams, GlobalHistory, bottomBoxComponentUpdate, getErrorByName, showError, showSuccess, showinfo } from '../../../../Helper';
import { GetAllBranches, GetAllSystemTemplates } from '../../../../Services';
import { CreateUnitTransactionConfig, GetUnitTransactionConfigById, UpdateUnitTransactionConfig } from '../../../../Services/UnitTransactionConfigServices';
import { ApplicationUserSearch } from '../../../../Services/userServices';
import Joi from 'joi';
import { emailExpression } from '../../../../Utils';
import { StaticLookupsIds } from '../../../../assets/json/StaticLookupsIds';

export const UnitTransactionConfigFields = () => {
  const parentTranslationPath = 'TransactionConfigurationView';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);
  const typingTimer = useRef(null);
  const [DataUser, setDataUser] = useState([]);
  const [Datatemplte, setDatatemplte] = useState([]);
  const [Teamstate, setTeamState] = useState({ ComplianceEmail: '', AccountsEmail: '' });
  const [ComplianceEmailState, setComplianceEmailState] = useState([]);
  const [ComplianceUserState, setComplianceUserState] = useState([]);
  const [AccountsUserState, setAccountsUserState] = useState([]);
  const [AccountsEmailState, setAccountsEmailState] = useState([]);
  const [SelectedReferred, setSelectedReferred] = useState([]);
  const [branchUSERData, setbranchUSERData] = useState([]);
  const [SelectedReferredAccounts, setSelectedReferredAccounts] = useState([]);
  const [SelectedTemplte, setSelectedTemplte] = useState([]);
  const [EditData, SetEditData] = useState(false);
  const [stateForm, setStateForm] = useState([]);
  const [branchList, setBranchList] = useState({ result: [], totalCount: 0 });
  const [inputList, setInputList] = useState([{ branch: null, user: null }]);
  const searchTimer = useRef(null);
  const [selectData, setSelectData] = useState([{ user: [], branch: [] }])
  const schema = Joi.object({
    ComplianceEmail: Joi.string().optional().allow('')
      .empty('')
      .regex(emailExpression)
      .messages({
        'string.pattern.base': t(`${translationPath}invalid-email`),
      }),
    AccountsEmail: Joi.string().optional().allow('')
      .empty('')
      .regex(emailExpression)
      .messages({
        'string.pattern.base': t(`${translationPath}invalid-email`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(Teamstate)


  function convertToNewFormat(inputArray) {
    const resultArray = [];

    inputArray.forEach(item => {
      const userId = (item && item.user && item.user && item.user[0]?.applicationUserId) || null;
      const branchId = (item && item.branch && item.branch[0]?.branchId) || null;
      if (userId !== null || branchId !== null) {
        resultArray.push({
          unitTransactionConfigListingAgentId: 0,
          branchId: branchId,
          userId: userId
        });
      }
    });

    return resultArray;
  }
  convertToNewFormat(selectData)


  const saveHandler = useCallback(async () => {
    const id = +GetParams('id')
    // setIsLoading(true);
    const res = (await UpdateUnitTransactionConfig(id, {
      "name": "",
      "templateIds": SelectedTemplte.map(item => item.systemTemplateId),
      "complianceNotifications": [...ComplianceEmailState, ...transformEmaiArray(ComplianceUserState)],
      "accountsNotifications": [...AccountsEmailState, ...transformEmaiArray(AccountsUserState)],
      "listingAgents": convertToNewFormat(selectData)
    }))
    // setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}updated-successfully`));
    } else
      showError(t(`${translationPath}create-failed`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SelectedTemplte, ComplianceEmailState, ComplianceUserState, AccountsEmailState, AccountsUserState, selectData]);

  const getAllUsers = async (name, branch) => {
    const res = await ApplicationUserSearch({ name: name || '', pageIndex: 0, pageSize: 10 });
    if (!(res && res.status && res.status !== 200)) {
      if (branch) {
        setbranchUSERData(res?.result || []);
      } else
        setDataUser(res?.result || []);
    } else setDataUser([]);
  };

  const getAllTemplates = useCallback(async (searchValue) => {
    const res = await GetAllSystemTemplates({ "search": searchValue, "pageIndex": 1, "pageSize": 25, "filterBy": "CreatedOn", "orderBy": 2, "SystemTemplateCategoryId":StaticLookupsIds.WithSpecificUser});
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setDatatemplte(res.result || []);
    } else {
      setDatatemplte([]);
    }
  }, []);

  const GetUnitTransactionConfigByIdAPI = useCallback(async (id) => {
    const res = await GetUnitTransactionConfigById(id);
    if (!(res && res.status && res.status !== 200))
      SetEditData(res);
    else SetEditData(null);
  }, []);

  const mapAndFilterNotifications = (notifications, filterCondition) =>
    notifications.filter(filterCondition).map(item => ({
      email: item.email,
      userId: item.userId || 0,
      fullName: item.userFullName,
      unitTransactionConfigNotificationId: item.unitTransactionConfigNotificationId
    }));
  const transformEmaiArray = (inputArray) => {
    return inputArray.map(item => {
      return { email: "", userId: item.applicationUserId };
    });
  }

  const handleAddClick = () => {
    setInputList([...inputList, { user: [], branch: [] }]); setSelectData([...selectData, { user: [], branch: [] }]);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);

    const select = [...selectData];
    select.splice(index, 1);
    setSelectData(select);
  };

  const changeStatusType = (value, i) => {
    const list = [...inputList];
    list[i].branch = value || null;
    setInputList(list);
    const select = [...selectData];
    select[i].branch = [value];
    setSelectData(select);
  };
const handleBranchesList =(checkValue)=>{

}
  const changeStatusUserType = (value, i) => {
    const list = [...inputList];
    list[i].user = value || null;
    setInputList(list);
    const select = [...selectData];
    select[i].user = [value];
    setSelectData(select);
  };

  const getBranch = async (branchName) => {
    const res = await GetAllBranches({ pageSize: 25, pageIndex: 1, branchName: branchName });
    if (!(res && res.status && res.status !== 200)) setBranchList({
      result: res.result,
      totalCount: res.totalCount
    });
    else setBranchList({ result: [], totalCount: 0 });
  };



  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='d-flex-v-center-h-end flex-wrap '>
        <ButtonBase className='btns theme-transparent mb-2' onClick={() => { GlobalHistory.push('/home/TransactionConfigurationView/view'); }}>
          <span>{t('Shared:cancel')}</span>
        </ButtonBase>
        <ButtonBase className='btns theme-solid mb-2'
          onClick={() => {
            saveHandler();
          }}
        >
          <span>{t('Shared:save')}</span>
        </ButtonBase>
      </div>
    );
  });
  useEffect(() => {
    if (EditData) {
      setSelectedTemplte(
        EditData.notificationTemplates.map(item => ({
          unitTransactionConfigNotificationTemplateId: item.unitTransactionConfigNotificationTemplateId,
          systemTemplateId: item.systemTemplateId,
          templateName: item.systemTemplateName
        }))
      );
      setComplianceUserState(mapAndFilterNotifications(EditData.complianceNotifications, item => item.userId !== null));
      setComplianceEmailState(mapAndFilterNotifications(EditData.complianceNotifications, item => item.email !== null));
      setAccountsUserState(mapAndFilterNotifications(EditData.accountsNotifications, item => item.userId !== null));
      setAccountsEmailState(mapAndFilterNotifications(EditData.accountsNotifications, item => item.email !== null));
      const FinalArray = EditData.listingAgents.map(item => ({
        user: [{
          fullName: item.userFullName,
          applicationUserId: item.userId
        }],
        branch: [{
          branchName: item.branchName,
          branchId: item.branchId
        }]
      }));

      if (FinalArray.length === 0) {
        setInputList([{ branch: null, user: null }])
        setSelectData([{ user: [], branch: [] }])
      }
      else {
        setInputList(FinalArray);
        setSelectData(FinalArray);
      }
    }
  }, [EditData]);

  useEffect(() => {
    getAllTemplates();
    getBranch();
  }, []);

  useEffect(() => {
    const id = +GetParams('id')
    if (id)
      GetUnitTransactionConfigByIdAPI(id)
  }, []);

  return (
    <div className='Transaction-Configuration-view-wrapper-add'>
      <div className='bulk-header-section'>{t(`${translationPath}TransactionConfiguration`)}</div>
      <div className='box-title'>{t(`${translationPath}Notificationconfiguration`)}</div>
      <div className='section-1'>
        <div>
          <div className='bulk-header--sub'>{t(`${translationPath}Compliance-Notification`)}</div>
          <div className='select-text'>
            {t(`${translationPath}To-select-the-receiver-for-the-transaction-notification-(compliance)`)}
          </div>
        </div>
        <div className='item-input-wraper'>
          <div className='item-input'>
            <Inputs
              idRef='EmailToRef'
              labelValue='Email'
              translationPath={translationPath}
              helperText={getErrorByName(schema, 'ComplianceEmail').message}
              error={getErrorByName(schema, 'ComplianceEmail').error}
              isWithError
              parentTranslationPath={parentTranslationPath}
              value={Teamstate.ComplianceEmail}
              endAdornment={(
                !schema.error && Teamstate.ComplianceEmail && <ButtonBase
                  disabled={Teamstate.ComplianceEmail === ''}
                  onClick={() => {
                    setComplianceEmailState((data) => {
                      const index = ComplianceEmailState.findIndex((item) => item.email === Teamstate.ComplianceEmail);
                      if (index !== -1) showError(t(`${translationPath}email-is-exists`));
                      else data.push({ email: Teamstate.ComplianceEmail, userId: 0 });
                      return [...data];
                    });
                    setTeamState({
                      ...Teamstate,
                      ComplianceEmail: ''
                    });
                  }}
                  className='p-2'
                >
                  <span className='mdi mdi mdi-plus mx-1' />
                </ButtonBase>
              )}
              onInputChanged={(e) => setTeamState({ ...Teamstate, ComplianceEmail: e.target.value })}
            />
          </div>
          <div className=' box-v-container w-100'>
            {ComplianceEmailState.map((item, index) => (
              <div index={index} className='email-wer d-flex d-flex-h-between  d-flex-v-center w-100'>
                <div>{item.email}</div>
                {' '}
                <div className='Remove-bbt'>
                  {' '}
                  <ButtonBase
                    onClick={() => {
                      setComplianceEmailState((data) => {
                        const index = ComplianceEmailState.findIndex((row) => row.email === item.email);
                        if (index !== -1) ComplianceEmailState.splice(index, 1);
                        return [...data];
                      });
                    }}
                    className='p-2'
                  >
                    {t(`${translationPath}Remove`)}
                  </ButtonBase>
                </div>
                {' '}
              </div>
            ))}
          </div>
        </div>
        <AutocompleteComponent
          idRef='RselectuserByRef'
          inputPlaceholder={t(`${translationPath}selectuser`)}
          labelValue={t(`${translationPath}user`)}
          selectedValues={SelectedReferred || []}
          getOptionSelected={(option) => option.applicationUserId === SelectedReferred.applicationUserId || ''}
          data={DataUser || []}
          inputEndAdornment={(
            SelectedReferred.length !== 0 && <ButtonBase
              onClick={() => {
                setComplianceUserState((data) => {
                  const index = ComplianceUserState.findIndex((item) => item.applicationUserId === SelectedReferred.applicationUserId);
                  if (index !== -1) showError(t(`${translationPath}user-is-exists`));
                  else
                    data.push(SelectedReferred);
                  return [...data];
                });
                setSelectedReferred([]);
              }}
              className='p-2'
            >
              <span className='mdi mdi mdi-plus mx-1' />
            </ButtonBase>
          )}
          onInputChange={(e) => {
            if (e && e.target && e.target.value)
              getAllUsers(e.target.value || '');
          }}
          multiple={false}
          displayLabel={(option) =>
            (option && option.fullName) || ''}
          chipsLabel={(option) => (option && option.fullName) || ''}
          withoutSearchButton
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            setSelectedReferred(newValue || []);
          }}
        />
        <div className=' box-v-container w-100'>
          {ComplianceUserState.map((item, index) => (

            <div index={index} className='email-wer d-flex d-flex-h-between  d-flex-v-center w-100'>
              <div>{item.fullName}</div>
              {' '}
              <div className='Remove-bbt'>
                {' '}
                <ButtonBase
                  onClick={() => {
                    setComplianceUserState((data) => {
                      const index = ComplianceUserState.findIndex((row) => row.applicationUserId === item.applicationUserId);
                      if (index !== -1) ComplianceUserState.splice(index, 1);
                      return [...data];
                    });
                  }}
                  className='p-2'
                >
                  {t(`${translationPath}Remove`)}
                </ButtonBase>
              </div>
              {' '}
            </div>
          ))}
        </div>
      </div>
      <div className='section-1'>
        <div>
          <div className='bulk-header--sub'>{t(`${translationPath}AccountsNotification`)}</div>
          <div className='select-text'>
            {' '}
            {t(`${translationPath}To-selectaccount`)}
          </div>
        </div>
        <div className='section-'>
          <div className='item-input'>
            <AutocompleteComponent
              inputPlaceholder={t(`${translationPath}selectuser`)}
              labelValue={t(`${translationPath}user`)}
              selectedValues={SelectedReferredAccounts || []}
              getOptionSelected={(option) => option.applicationUserId === SelectedReferredAccounts.applicationUserId || ''}
              data={DataUser || []}
              inputEndAdornment={(
                SelectedReferredAccounts.length !== 0 && <ButtonBase
                  onClick={() => {
                    setAccountsUserState((data) => {
                      const index = AccountsUserState.findIndex((item) => item.applicationUserId === SelectedReferredAccounts.applicationUserId);
                      if (index !== -1) showError(t(`${translationPath}user-is-exists`)); // ComplianceEmailState.splice(index, 1);
                      else
                        data.push(SelectedReferredAccounts);
                      return [...data];
                    });
                    setSelectedReferredAccounts([]);
                  }}
                  className='p-2'
                >
                  <span className='mdi mdi mdi-plus mx-1' />
                </ButtonBase>
              )}
              onInputChange={(e) => {
                if (e && e.target && e.target.value)
                  getAllUsers(e.target.value || '');
              }}
              multiple={false}
              displayLabel={(option) =>
                (option && option.fullName) || ''}
              chipsLabel={(option) => (option && option.fullName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChange={(event, newValue) => {
                setSelectedReferredAccounts(newValue || []);
              }}
            />  </div> </div>

        <div className=' box-v-container w-100'>
          {AccountsUserState.map((item, index) => (
            <div index={index} className='email-wer d-flex d-flex-h-between  d-flex-v-center w-100'>
              <div>{item.fullName || item.email || ''}</div>
              {' '}
              <div className='Remove-bbt'>
                {' '}
                <ButtonBase
                  onClick={() => {
                    setAccountsUserState((data) => {
                      const index = AccountsUserState.findIndex((row) => row.applicationUserId === item.applicationUserId);
                      if (index !== -1) AccountsUserState.splice(index, 1);
                      return [...data];
                    });
                  }}
                  className='p-2'
                >
                  {t(`${translationPath}Remove`)}
                </ButtonBase>
              </div>
              {' '}
            </div>
          ))}
        </div>
        <div className='mt-3'>
          <div>
            <Inputs
              idRef='EmailToRef2'
              labelValue='Email'
              helperText={getErrorByName(schema, 'AccountsEmail').message}
              error={getErrorByName(schema, 'AccountsEmail').error}
              isWithError
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              value={Teamstate.AccountsEmail}
              endAdornment={(
                !schema.error && Teamstate.AccountsEmail !== '' && <ButtonBase
                  disabled={Teamstate.AccountsEmail === ''}
                  onClick={() => {
                    setAccountsEmailState((data) => {
                      const index = AccountsEmailState.findIndex((item) => item.email === Teamstate.AccountsEmail);
                      if (index !== -1) showError(t(`${translationPath}email-is-exists`));
                      else data.push({ email: Teamstate.AccountsEmail });
                      return [...data];
                    });
                    setTeamState({
                      ...Teamstate,
                      AccountsEmail: ''
                    });
                  }}
                  className='p-2'
                >
                  <span className='mdi mdi mdi-plus mx-1' />
                </ButtonBase>
              )}
              onInputChanged={(e) => setTeamState({ ...Teamstate, AccountsEmail: e.target.value })}
            />
          </div>
          <div className=' box-v-container w-100'>
            {AccountsEmailState.map((item, index) => (
              <div index={index} className='email-wer d-flex d-flex-h-between  d-flex-v-center w-100'>
                <div>{item.fullName || item.email || ''}</div>
                <div className='Remove-bbt'>
                  {' '}
                  <ButtonBase
                    onClick={() => {
                      setAccountsEmailState((data) => {
                        const index = AccountsEmailState.findIndex((row) => row.email === item.email);
                        if (index !== -1) AccountsEmailState.splice(index, 1);
                        return [...data];
                      });
                    }}
                    className='p-2'
                  >
                    {t(`${translationPath}Remove`)}
                  </ButtonBase>
                </div>
                {' '}
              </div>
            ))}

          </div>
        </div>
      </div>
      <div>
        <div className='form-item mt-3' style={{ maxWidth: '36rem' }}>
          <AutocompleteComponent
            idRef='templteRef'
            labelValue={t(`${translationPath}Template`)}
            inputPlaceholder={t(`${translationPath}Slect-Template`)}
            selectedValues={SelectedTemplte}
            data={Datatemplte || []}
            getOptionSelected={(option) =>
              SelectedTemplte && SelectedTemplte.findIndex((item) => item && item.systemTemplateId === option.systemTemplateId) !== -1 || ''}
            chipsLabel={(option) => (option.templateName && option.templateName) || ''}
            displayLabel={(option) => (option.templateName && option.templateName) || ''}
            renderOption={(option) => (option.templateName && option.templateName) || ''}
            multiple
            withoutSearchButton
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (typingTimer.current) clearTimeout(typingTimer.current);
              typingTimer.current = setTimeout(() => { getAllTemplates(value); }, 700);
            }}
            onChange={(event, newValue) => {
              const languageLookupIds = newValue && newValue.map((item) => item);
              setSelectedTemplte(languageLookupIds || []);
            }}
          />
        </div>
      </div>
      <div>
        <div className="bulk-header--sub">{t(`${translationPath}Primary`)}</div>
        <div className="select-text">{t(`${translationPath}Toselect`)}</div>
        {inputList && inputList.map((item, i) => {
          const selectedBranchIds = selectData.flatMap(dataItem => 
            dataItem.branch ? dataItem.branch.map(branch => branch?.branchId) : []
          );
        return(
         <div index={i} className=''>
            <div className='filter-section'>
              <div className='section' />
               <div className='search-agentRotation select-55'>
             <AutocompleteComponent
               idRef='systemReportFormsleoRef'
               multiple={false}
               data={(branchList && branchList.result) || []}
               chipsLabel={(option) => option.branchName || ''}
               displayLabel={(option) => option.branchName || ''}
               withoutSearchButton
               selectedValues={selectData && selectData[i] && selectData[i].branch[0] || []}
               getOptionSelected={(option) =>
                 selectData && selectData[i] && selectData[i].branch.findIndex((items) => items && items.branchId === option.branchId) !== -1 || ''}
               inputPlaceholder={t(`${translationPath}select-branchName`)}
               labelValue={t(`${translationPath}branchName`)}
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               onInputKeyUp={(e) => {
                 const { value } = e.target;
                 if (typingTimer.current) clearTimeout(typingTimer.current);
                 typingTimer.current = setTimeout(() => {
                   getBranch(value);
                 }, 700);
               }}
               onChange={(event, newValue) => {
                 if (newValue && selectedBranchIds.includes(newValue?.branchId)) {
                   showinfo(t(`${translationPath}This-branch-is-already-selected!`))
                   return;
                 }
                 changeStatusType(newValue && newValue, i);
               }}
             />
      </div>
      <div className='search-agentRotation'>
        <AutocompleteComponent
          idRef='rfo456ldTitleoRef'
          multiple={false}
          data={branchUSERData || []}
          displayLabel={(option) => (option && option.fullName) || ''}
          chipsLabel={(option) => (option && option.fullName) || ''}
          withoutSearchButton
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (typingTimer.current) clearTimeout(typingTimer.current);
            typingTimer.current = setTimeout(() => { getAllUsers(value, 'value' || null); }, 700);
          }}
          selectedValues={selectData && selectData[i] && selectData[i].user && selectData[i].user[0] || []}
          getOptionSelected={(option) =>
            selectData && selectData[i] &&
            selectData[i].user.findIndex((items) => items && items.applicationUserId === option.applicationUserId) !== -1 || ''}
          inputPlaceholder={t(`${translationPath}select-team-member`)}
          labelValue={t(`${translationPath}team-member`)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            changeStatusUserType(newValue && newValue, i);
          }}
        />
      </div>
    </div>
    <div>{inputList.length !== 1 && (
      <div className='remove-c opation-tool mx-1'>
         <ButtonBase
          size='small'
          title={t(`${translationPath}Remove`)}
          onClick={() => handleRemoveClick(i)}
        >
        <span class="mdi mdi-minus"></span> {t(`${translationPath}Remove`)}
       </ButtonBase>
      </div>
    )}</div>
    {' '}
    {inputList.length - 1 === i && (
      <div className='add-c opation-tool add mx-1'>
        <div title={t(`${translationPath}add`)} onClick={handleAddClick}><span class="mdi mdi-plus"></span> {t(`${translationPath}ADD`)}</div></div>
    )}
  </div>
)
})}
      </div>
    </div>
  );
};
