
export const LeadsSalesPermissions = {
    ViewAndSearchInSaleLeads: {
        permissionsId: "0edb0afb-9597-4dab-c9e2-08d92ff25bf0",
        permissionsName: "View and search in sale leads",
        componentsId: null,
        components: null

    },
    ViewLeadDetails: {
        permissionsId: "7401f681-5ece-43f2-c9e3-08d92ff25bf0",
        permissionsName: "View lead details",
        componentsId: null,
        components: null
    },
    ViewMatchingUnits: {
        permissionsId: "a2ec7b88-8363-4eab-c9e4-08d92ff25bf0",
        permissionsName: "View Matching Units",
        componentsId: null,
        components: null
    },
    AddNewLead: {
        permissionsId: "fcf50bd0-f80f-4e50-c9e5-08d92ff25bf0",
        permissionsName: "Add new lead",
        componentsId: null,
        components: null
    },
    EditLeadDetails: {
        permissionsId: "2c1938a9-c675-434b-c9e6-08d92ff25bf0",
        permissionsName: "Edit lead details",
        componentsId: null,
        components: null
    } , 

    GetActivitiesForLead  : {
        permissionsId: '0ad29e82-22e4-48b5-a895-08da6e2b177d',
        permissionsName: 'Get Activities For Lead',
        componentsId: null,
        components: null
      } , 

      GetAgentLeadAssigmentHistory :{
        permissionsId: '752d2ad5-43fc-4f6e-a888-08da6e2b177d',
        permissionsName: 'Get Agent Lead Assigment History',
        componentsId: null,
        components: null
      } , 

      ImportLead :  {
        permissionsId: 'fc616802-012e-4897-a88b-08da6e2b177d',
        permissionsName: 'Import Lead',
       componentsId: null,
        components: null
      } , 
      CloseLead : {
        permissionsId: 'a4052661-e885-45a5-a88e-08da6e2b177d',
        permissionsName: 'Close Lead',
        componentsId: null,
        components : null
      } , 
      ReassignLead : {
        permissionsId: '527d85aa-f66a-4c3e-a892-08da6e2b177d',
        permissionsName: 'Reassign Lead',
        componentsId: null,
        components: null
      } , 
      CloneLead : {
        permissionsId: '3db02fb4-b3b5-4bc6-475a-08d9aa7e8df8',
        permissionsName: 'Clone Lead',
        componentsId: null,
        components: null
      } , 
      SendToRotation :
      {
        components : null , 
        componentsId : null , 
        permissionsId : 'ff572d73-ba53-466d-8398-08db0f594284' , 
        permissionsName : "Send To Rotation" 
      } , 
      AddNewActivity :  {
        permissionsId: "21a02199-d83f-4697-2870-08dbaf79a1bb",
        permissionsName: "Add New Activity",
        description: null,
        componentsId: null,
        components: null
    } , 
    
    ReassignSellerLead : {
       permissionsId: "89bfcb4c-17eb-4d87-8872-08dc0129f7d6",
       permissionsName: "Reassign Seller Lead",
       description: null,
       componentsId: null,
       components: null
    } , 
     ViewTasksForLead :{
       permissionsId: "f8ab2a08-18f6-4f64-19b6-08dc313da57c",
       permissionsName: "View Tasks For Lead",
       description: null,
       componentsId: null,
       components: null
    },
    AddNewTask :  {
       permissionsId: "3cf66010-0823-42be-19b7-08dc313da57c",
       permissionsName: "Add New Task",
       description: null,
       componentsId: null,
       components: null
  } , 
  SendToTheLeadsPool : {
     permissionsId: "5bcb5d27-e2fa-44f8-c4c1-08dc683b0c43",
     permissionsName: "Send To The Leads Pool",
     description: null,
     componentsId: null,
     components: null
},
SearchAndMatch : {
     permissionsId: "1728c16e-96c1-4af2-796c-08dcaf947ea8",
     permissionsName: "Search & Match",
     description: null,
     componentsId: null,
     components: null
 },
SendToTheLeadsPoolAssets : {
   permissionsId: "99861652-c79f-4c21-506d-08dce2e2dc64",
    permissionsName: "Send To The Leads Pool (Assets)",
    description: null,
    componentsId: null,
    components: null
}

};
