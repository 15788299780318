
import { config } from '../config';
import { HttpServices } from '../Helper';


export const GetAllKycConfigration = async () => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Kyc/GetAllKycConfigration`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllRiskRatingConfigration = async () => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Kyc/GetAllRiskRatingConfigration`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateRiskRatingConfigration = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Kyc/UpdateRiskRatingConfigration`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetNationalityConfigurations = async ({searchValue}) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Kyc/GetNationalityConfigurations?search=${searchValue}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateNationalityConfigration = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Kyc/UpdateNationalityConfigration`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};