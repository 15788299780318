export const AMLViewPermissions = {
  ViewAMLAdminCheckerModule: {
    permissionsId: "5e5b5b1b-9499-4f96-44f7-08db5f99228b",
    permissionsName: "View AML Admin Checker Module",
    description: null,
    componentsId: null,
    components: null,
  },

  CancelApproveRequest: {
    permissionsId: "3c66d67f-147e-4743-44f8-08db5f99228b",
    permissionsName: "Cancel/Approve Request",
    description: null,
    componentsId: null,
    components: null,
  },

  ViewNeedRevisionButton: {
    permissionsId: "200cb878-0bc4-4b00-f94d-08dc0f48c1d2",
    permissionsName: "View Need Revision Button",
    description: null,
    componentsId: null,
    components: null,
  },

  ApproveAction: {
    permissionsId: "63fab715-7ade-424b-5dd0-08dc7403ebc2",
    permissionsName: "Approve Action",
    description: null,
    componentsId: null,
    components: null,
  },

  RejectAction: {
    permissionsId: "f450d421-e4f9-443d-5dd1-08dc7403ebc2",
    permissionsName: "Reject Action",
    description: null,
    componentsId: null,
    components: null,
  },

  ReassignToRequester: {
    permissionsId: "1d92cb04-647f-4858-5dd2-08dc7403ebc2",
    permissionsName: "Reassign to Requester",
    description: null,
    componentsId: null,
    components: null,
  },

  WithdrawTransaction: {
    permissionsId: "6c7e0e0c-1d12-4389-5dd3-08dc7403ebc2",
    permissionsName: "Withdraw Transaction",
    description: null,
    componentsId: null,
    components: null,
  },

  UploadDocument: {
    permissionsId: "1262971b-978b-4a26-5dd4-08dc7403ebc2",
    permissionsName: "Upload Document",
    description: null,
    componentsId: null,
    components: null,
  },

  DownloadDocument: {
    permissionsId: "a479340f-a0ef-4552-5dd5-08dc7403ebc2",
    permissionsName: "Download Document",
    description: null,
    componentsId: null,
    components: null,
  },

  ViewDocument: {
    permissionsId: "338fa4bc-dc2a-4505-5dd6-08dc7403ebc2",
    permissionsName: "View Document",
    description: null,
    componentsId: null,
    components: null,
  },

  ViewHistory: {
    permissionsId: "b973627e-0157-428c-7944-08dcaf947ea8",
    permissionsName: "View History",
    description: null,
    componentsId: null,
    components: null,
  },
};
