import React from "react";
import clsx from "clsx";
import moment from "moment";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  CustomTimeline,
  CustomCopyToClipboard,
} from "../../../../../../../Components";
import { useSelectedTheme, useTranslate } from "../../../../../../../Hooks";

// Icons
import { CornerUpLeft } from "../../../../../../../assets/icons";

// Styles
const useStyles = makeStyles((theme) => ({
  timelineContainer: {
    marginTop: "16px",
  },
  timelineContentWrapper: {
    paddingInlineStart: "20px",
    paddingBottom: "24px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
      gap: "20px",
    }
  },
  infoSection: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  infoHeading: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    color: theme.palette.text.primary,
  },
  infoItem: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  infoValue: {
    fontWeight: 600,
  },
  open: {
    color: theme.palette.text.success_primary,
    fontWeight: 500,
    lineHeight: "20px",
    fontSize: "14px",
  },
  close: {
    color: theme.palette.text.error_primary,
    fontWeight: 500,
    lineHeight: "20px",
    fontSize: "14px",
  },
  dateSection: {
    display: "flex",
    gap: "10px",
  },
  dateAndTime: {
    display: "flex",
    gap: "10px",
    color: theme.palette.text.text_quarterary,
  },
  dateDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.down('xs')]: {
      alignItems: "flex-start",
    }
  },
  date: {
    fontSize: "14px",
    fontWeight: 500,
  },
  cornerUpLeftIcon: {
    marginTop: "18px",
  },
  emptyState: {
    fontSize: '18px',
    paddingTop: '16px',
  }
}));

function UserActivityTimeline({ timelineItems, totalCount }) {
  const styles = useStyles();

  const { translate: sharedTranslate } = useTranslate("SharedV2");

  if (totalCount === 0) {
    return (
      <Typography className={styles.emptyState} variant="h6" align="center" color="textSecondary">
        {sharedTranslate("no-data-avlbl")}
      </Typography>
    )
  }

  return (
    <CustomTimeline
      timelineData={timelineItems}
      component={TimelineItemContent}
      timelineDotTopMargin="5px"
      timelineContainerClasses={styles.timelineContainer}
    />
  );
}

export default UserActivityTimeline;

// Custom Timeline Item Component
function TimelineItemContent({ item }) {
  const styles = useStyles();

  const formattedDate = item?.createdOn
    ? moment(item?.createdOn).locale("en").format("D MMM YYYY")
    : null;

  const formattedTime = item?.createdOn
    ? moment(item?.createdOn).locale("en").format("h:mm a")
    : null;

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <Box className={styles.timelineContentWrapper}>
      <Box className={styles.infoSection}>
        <Typography className={styles.infoHeading} component="h3">
          {item?.subject}
        </Typography>
        {item?.leadName &&
        <Typography
          className={styles.infoItem}
          variant="body1"
          component="span"
        >
          Lead Name:{" "}
          <strong className={styles.infoValue}>{item?.leadName}</strong>
        </Typography>}
        {item?.leadId &&
        <Typography
          className={styles.infoItem}
          variant="body1"
          component="span"
        >
          Lead Id:
          <CustomCopyToClipboard
            data={item?.leadId} // copy
            childrenData={"#" + item?.leadId} // render
            copyDoneShowTime={1000}
          />
        </Typography>}

        {item?.unitName &&
          <Typography
          className={styles.infoItem}
          variant="body1"
          component="span"
        >
          Unit Name:{" "}
          <strong className={styles.infoValue}>{item?.unitName}</strong>
        </Typography>}
        {item?.unitId &&
          <Typography
          className={styles.infoItem}
          variant="body1"
          component="span"
        >
          Unit Id:
          <CustomCopyToClipboard
            data={item?.unitId} // copy
            childrenData={"#" + item?.unitId} // render
            copyDoneShowTime={1000}
          />
        </Typography>}
        
        {item?.contactName &&
          <Typography
          className={styles.infoItem}
          variant="body1"
          component="span"
        >
          Contact Name:{" "}
          <strong className={styles.infoValue}>{item?.contactName}</strong>
        </Typography>}
        {item?.contactId &&
          <Typography
          className={styles.infoItem}
          variant="body1"
          component="span"
        >
          Contact Id:
          <CustomCopyToClipboard
            data={item?.contactId} // copy
            childrenData={"#" + item?.contactId} // render
            copyDoneShowTime={1000}
          />
        </Typography>}
        <Typography
          className={clsx(styles.infoItem, item?.isOpen ? styles.open : styles.close)}
          variant="body1"
          component="span"
        >
          {item?.isOpen ? "Open" : "Closed"}
        </Typography>
      </Box>
      <Box className={styles.dateSection}>
        <Box className={styles.dateDetails}>
          <Box className={styles.dateAndTime}>
            {formattedDate && (
              <Typography className={styles.date}>{formattedDate}</Typography>
            )}
            {formattedTime && (
              <Typography className={styles.date}>{formattedTime}</Typography>
            )}
          </Box>
          <Typography
            className={styles.infoItem}
            variant="body1"
            component="span"
          >
            Assign to: <strong className={styles.infoValue}>{item?.assignTo}</strong>
          </Typography>
          <Typography
            className={styles.infoItem}
            variant="body1"
            component="span"
          >
            Created by:{" "}
            <strong className={styles.infoValue}>{item?.createdBy}</strong>
          </Typography>
        </Box>
        <CornerUpLeft
          width="24"
          height="24"
          fill={palette.foreground.quarterary}
          className={styles.cornerUpLeftIcon}
        />
      </Box>
    </Box>
  );
}
